import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'

const NotFoundPage = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    navigate(`https://tdc.dk${pathname}`)
  }, [])
  return <></>
}

export default NotFoundPage
